.mypopup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.popupfade {
    opacity: 0;
    transition: opacity .15s linear;
}

.popupshow {
    display: block;
    opacity: 1;
}

.mypopup-dialog {
    transition: transform .3s ease-out;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
}

.mypopup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
    outline: 0;
}

.mypopup-header {
    padding: 10px;
    border-bottom: 2px solid #2196f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    font-size: 25px;
    background-color: #2196f3;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Dosis', sans-serif;
}

.mypopup-body {
    position: relative;
    flex: 1 1 auto;
    padding: 23px 20px;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto Condensed', sans-serif;
}

.mypopup-footer {
    align-items: center;
    padding: 10px;
    border-top: 2px solid #2196f3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
}

.mypopup-footer .my-button {
    min-width: 90px;
}

.mypopup-error .mypopup-footer {
    border-top: 2px solid rgba(246, 71, 71, 1) !important;
}

.mypopup-sucess .mypopup-footer {
    border-top: 2px solid rgba(0, 179, 134, 1) !important;
}

.mypopup-error .mypopup-header {
    background-color: rgba(246, 71, 71, 1) !important;
    border-bottom: 2px solid rgba(246, 71, 71, 1) !important;
}

.mypopup-sucess .mypopup-header {
    background-color: rgba(0, 179, 134, 1) !important;
    border-bottom: 2px solid rgba(0, 179, 134, 1) !important;
}

.mypopup-error .my-button {
    background-color: rgba(246, 71, 71, 1) !important;
}

.mypopup-sucess .my-button {
    background-color: rgba(0, 179, 134, 1) !important;
}

.mypopup-content .my-button {
    background-color: #2196f3;
}

.my-button-sucess {
    background-color: rgba(0, 179, 134, 1) !important;
}

.my-button-error {
    background-color: rgba(255, 107, 114, 1) !important;
}

.my-button-warning {
    background-color: rgba(255, 170, 0, 1) !important;
}

.mypopup-dialog.quicktask-popup{
    max-width: 500px;
}

.quicktask-popup span{
    font-weight: bold;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: inline-block;
    color: #00ace6;
}
.quicktask-popup .myRow{
    font-size: 17px;
}
.quicktask-popup .mypopup-body{
    font-size: 16px;
}
.quicktask-popup smart-form-field{
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 10px;
}
.quicktask-popup .input-field{
    width: 30%;
}

.mypopup-scaner {
    transition: transform .3s ease-out;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
}
.mypopup-scaner-header {
    padding: 5px 10px;
    border-bottom: 2px solid #00ace6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    font-size: 25px;
    background-color: #00ace6;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Dosis', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mypopup-scaner-body {
    position: relative;
    flex: 1 1 auto;
    padding: 23px 20px;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto Condensed', sans-serif;
    min-height: 250px;
}

.mypopup-scaner-footer {
    align-items: center;
    padding: 10px;
    border-top: 2px solid #00ace6;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
}
.popup-close-action{
    width: max-content;
    font-size: 25px;
    right: 20px;
    position: absolute;
    cursor: pointer;
}
button.html5-qrcode-element{
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 900;
    height: auto;
    letter-spacing: .6px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px 10px;
    text-align: center;
    width: auto;    
    margin-top: 3px;
}
#html5-qrcode-button-camera-permission{
    background-color: rgba(0, 153, 115, 0.9);
}
#html5-qrcode-button-camera-start{
    background-color: #2196f3;
}
#html5-qrcode-button-torch{
    background-color: #ffaa00;
}
#html5-qrcode-button-camera-stop{
    background-color: #e57373;
}
.title-label {
    width: 100%;
    position: relative;
    color: #404040;
    font-weight: 700;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.details-text {
    width: 100%;
    padding: 1px 6px;
    position: relative;
    font-size: 14px;
    margin-bottom: 3px;
    font-family: 'Poppins', sans-serif;
    color: rgb(108, 122, 137);
    font-weight: 700;
}
@media (max-width: 768px) {
    .mypopup-scaner>.mypopup-content{
        position: fixed;
        bottom: 0px;
        left: 0px;
    }
}