smart-form-field {
    display: flex;
    flex-wrap: wrap;
    font-family: Roboto, sans-serif;
    margin-bottom: 10px;
}

.field-label {
    width: 25%;
    overflow: hidden;
    padding-right: 10px;
}

.input-field {
    width: 70%;
}

.input-smart-label {
    color: #36454F;
    font-weight: 700;
    font-size: 17px;
    position: relative;
    padding-top: 4px;
}

.input-smart-cell {
    width: 100%;
    padding: 4px 8px;
    position: relative;
    outline: none;
    border: 2.5px solid #a6a6a6;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
}

.label-required {
    font-size: 12px;
    color: rgb(255, 102, 102);
    font-weight: 700;
    margin-left: 5px;
    margin-top: 6px;
    position: absolute;
}

.input-field.lg-field {
    width: 95%;
}

.field-label.lg-field {
    margin-bottom: 5px;
    width: 100%;
}

.input-smart-cell:focus {
    border-color: rgba(26, 163, 255, 1) !important;
}

.mydropdown {
    cursor: pointer;
    height: 31px;
    position: relative;
}

.mydropdown input {
    background-color: #ffffff !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 14px;
    width: calc(100% - 30px);
    margin-right: 5px;
    cursor: inherit;
}

.mydropdown i {
    font-size: 20px;
    position: absolute;
    color: #808080;
    z-index: 10;
    margin-left: 1.5%;
}

.drop-input-cont .drop-arrow {
    font-size: 20px;
    position: absolute;
    color: #808080;
    margin-top: 2.5px;
}

.mydropdown .dropdown_content {
    position: absolute;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    margin-top: 3px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    z-index: 150;
    margin-left: 0px;
    padding: 8px 0px;
    display: none;
    border-radius: 5px;
    font-family: Roboto Condensed, sans-serif;
}

.mydropdown .dropdown_content .dropdown_item {
    color: rgb(23, 43, 77);
    padding: 5px 15px;
    display: block;
    cursor: pointer;
    font-size: 15px;
    text-align: left;
}

.dropdown_item:hover {
    background: #f2f2f2;
}

.dropdown_item i {
    font-size: 17px;
    color: #808080;
    right: 15px;
    display: none;
    padding-top: 2px;
}

.dropdown_item.selected i {
    display: inline-block !important;
    color: #00ace6 !important;
}

.dropdown_item.selected {
    color: #00ace6 !important;
    font-weight: 600;
}

.reverse {
    top: auto;
    bottom: 35px;
}

.mydropdown .dropdown_content.show {
    display: block;
}

.drop-input-cont {
    width: 100%;
    padding: 4px 8px;
    position: relative;
    outline: none;
    border: 2.5px solid #a6a6a6;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
}

.smart-red-border {
    border-color: #fe7786;
}

.smart-green-border {
    border-color: #00b386;
}

.smart-blue-border {
    border-color: rgba(26, 163, 255, 1);
}

.lg-field .mydropdown {
    height: auto;
}

.toogle-button-cont {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    font-family: Roboto, sans-serif;
    padding: 5px;
    background-color: #00ace6;
    width: max-content;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 2px 7px -4px #0009;
}

.toggle-button {
    color: #ffffff;
    padding: 2px 25px;
    font-weight: 600;
    cursor: pointer;
    flex-shrink: 0;
    background-color: rgba(0, 172, 230, 1);
    box-shadow: inset 0px 0px 150px -130px rgba(0, 0, 0, 0.75);
}

.toggle-button:first-child {
    border-radius: 12px 0px 0px 12px;
}

.toggle-button:last-child {
    border-radius: 0px 12px 12px 0px;
}

.toggle-selcted {
    background-color: #ffffff;
    color: #00ace6;
    box-shadow: none;
}

.disable-toggle .toggle-button {
    cursor: not-allowed;
}

.toggle-button:hover {
    box-shadow: inset 0px 0px 150px -102px rgba(0, 0, 0, 0.75);
}

.toggle-selcted:hover {
    box-shadow: none;
}

.disable-toggle {
    opacity: 0.65;
}

smart-toggle-field {
    display: flex;
    flex-wrap: wrap;
    font-family: Roboto, sans-serif;
    margin-bottom: 0px;
}

.dropdown_search {
    color: rgb(23, 43, 77);
    padding: 0px 15px;
    display: block;
    cursor: pointer;
    font-size: 15px;
    text-align: left;
    border-bottom: 2.5px solid #00ace6;
    padding-bottom: 3px;
    margin-bottom: 2px;
}

.dropdown_list {
    max-height: 160px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    position: relative;
}

.dropdown_list::-webkit-scrollbar {
    width: 6px;
}

.dropdown_list::-webkit-scrollbar-track {
    background-color: transparent;
}

.dropdown_list::-webkit-scrollbar-thumb {
    background: #00ace6;
}

.dropdown_list::-webkit-scrollbar-thumb:hover {
    background: #00ace6;
}

.dropdown_title {
    color: rgb(23, 43, 77);
    padding: 3px 15px;
    display: block;
    font-size: 12px;
    text-align: left;
    letter-spacing: 0.5px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.07);
    color: rgb(94, 77, 178);
    background-color: rgb(223, 216, 253);
}

.option-chip {
    font-size: 10px;
    margin-left: 5px;
    border-radius: 6px;
    background: #bfbfbf;
    padding: 1px 8px;
    color: #ffffff;
    vertical-align: middle;
    font-weight: 500;
    font-family: Poppins, sans-serif;
}

.chip_Completed {
    background: #49cc90;
}

.chip_StandBy {
    background: #ffd480;
}

.chip_Started {
    background: #80aaff;
}

.chip_Stopped {
    background: #fe7786;
}

.toogle-sm-button-cont {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    font-family: Roboto, sans-serif;
    border: 2px solid #00ace6;
    width: max-content;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 7px -4px #0009;
}

.toggle-sm-button {
    color: rgba(0, 172, 230, 1);
    padding: 2px 10px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    flex-shrink: 0;
    border-left: 1px solid rgba(0, 172, 230, 1);
    border-right: 1px solid rgba(0, 172, 230, 1);
}

.toggle-sm-button:first-child {
    border-radius: 7px 0px 0px 7px;
    border-left: none !important;
}

.toggle-sm-button:last-child {
    border-radius: 0px 7px 7px 0px;
    border-right: none !important;
}

.toggle-sm-selcted {
    background-color: rgba(0, 172, 230, 1);
    box-shadow: none;
    color: #ffffff;
}

.toggle-sm-button:hover {
    box-shadow: inset 0px 0px 150px -102px rgba(0, 0, 0, 0.6);
}

.toggle-sm-selcted:hover {
    box-shadow: none;
}

.create-form{
    padding: 10px 30px;
}
.create-form-toolbar{
    padding: 10px 30px;
    justify-content: center;
}
.scanbutton{
    width: 40px;
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}
.scaner-field{
    position: relative;
    display: flex;
}
.scaner-field>input{
    width: 90%;
}
.upload-img-cont{
    width: 150px;
    margin: auto;
}
.upload-img-preview{
    width: 125px;
    height: 125px;
    background-color: #e6e6e6;
    border-radius: 50%;
    font-size: 35px;
    font-weight: 600;
    margin: auto;
    font-family: Poppins, sans-serif;
    overflow: hidden;
    border: 6px solid rgba(154, 217, 255, 255);
    color: #ffffff;
    text-transform: uppercase;
}
.upload-img-preview>img {
    width: 120px;
    height: 120px;
}
.upload-img-button{
    margin-top: 5px;
    position: relative;
}
.upload-img-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    font-size: 23px;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
}
.create-upload-form{
    padding: 4px 30px;
}
@media (max-width: 768px) {
    .field-label {
        width: 100%;
        margin-bottom: 2px;     
    }
    .input-smart-label{
        font-size: 14px;
    }
    .input-field{
        width: 100%;
    }
    .input-field.lg-field{
        width: 100%;
    }
    smart-form-field{
        margin-bottom: 4px;
    }
    .option-chip{
        margin-left: 0px;
        padding: 1px 2px;
    }
    .mydropdown .dropdown_content .dropdown_item{
        font-size: 14px;
        overflow: hidden;
        padding: 4px 8px;
    }
    .create-form{
        padding: 5px 20px;
    }
    .create-form-toolbar{
        position: fixed;
        bottom: 0px;
        width: 100%;
        /* box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .31); */
        background-color: #ffffff;
        border-top: 2px solid #cccbcb;
        left: 0px;
    }
    .mob-padding{
        padding: 35px;
    }
}