.topNav {
    background-color: #fff;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .31);
    font-family: Poppins, sans-serif;
    min-height: 55px;
    padding: 5px 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
}

.logo-title {
    font-family: "Agbalumo";
    font-size: 30px;
    color: #8c8c8c;
    float: left;
    letter-spacing: 1px;
    padding: 5px;
    position: relative;
}

.logo-title span {
    color: #00ace6;
}

.greetType {
    position: relative;
    float: right;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 5px;
    color: #8c8c8c;
    text-align: right;
}

.greetType>span {
    color: #00ace6;
    font-weight: 800;
}

.right-part {
    position: relative;
    float: right;
    font-weight: 900;
    letter-spacing: 1px;
    color: #8c8c8c;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.optionItem {
    flex: 0 0 auto;
    font-size: 23px;
    padding: 5px 10px;
    cursor: pointer;
    margin: auto;
}

.profileimg {
    width: 45px;
    height: 45px;
    background-color: #d9d9d9;
    border-radius: 50%;
}

.mob-nav-toggle {
    position: relative;
    float: left;
    font-size: 32px;
    padding-right: 8px;
    padding-left: 2px;
    cursor: pointer;
    color: #595959;
    display: none;
}

.total-body {
    width: 100%;
    margin-top: 65px;
    min-height: calc(100vh - 65px);
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.left-total-body {
    min-height: 100%;
    width: 15%;
    flex: 0 0 auto;
}

.right-total-body {
    min-height: 100%;
    flex: 0 0 auto;
    width: 85%;
    background-color: rgba(255, 255, 255, 1);
}

.fullmenu {
    background: rgb(0, 138, 230);
    background: linear-gradient(45deg, rgba(0, 138, 230, 1) 37%, rgba(26, 163, 255, 1) 67%, rgba(102, 194, 255, 1) 100%);
    min-height: 100%;
    position: relative;
    width: 100%;
    z-index: 15;
    padding-top: 20px;
}

.sidemenu-itemlist {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin-top: 10px;
}

.menu-item {
    color: #ffffff;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 13px;
    margin: 2px 0;
    padding: 8px 10px 8px 20px;
}

.menu-item i {
    font-size: 15px;
    margin: 0 5px 0 0;
}

.menu-item.selected {
    background: #ffffff;
    border-left: 5px solid #008ae6;
    color: #008ae6;
    font-weight: 700;
    font-size: 14px;
}

.menu-item.selected i {
    font-size: 17px;
}

.full-issue-container {
    padding: 0px 40px;
    background-color: #ffffff;
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
    display: flex;
    justify-content: center;
}

.full-issue-container::-webkit-scrollbar {
    width: 6px;
}

.full-issue-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.full-issue-container::-webkit-scrollbar-thumb {
    background: rgba(0, 115, 215, 1);
}

.full-issue-container::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 115, 215, 1);
}


.issue-container {
    width: 270px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: rgb(247, 248, 249);
    box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.92);
    -webkit-box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.92);
    -moz-box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.92);
    min-height: 200px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.project-title {
    font-family: Poppins, sans-serif;
    font-size: 23px;
    font-weight: 800;
    color: rgb(23, 43, 77);
    padding: 10px 40px;
}

.project-title>span {
    color: #00ace6;
}

.issue-title {
    color: rgb(98, 111, 134);
    padding: 8px 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto, sans-serif;
}

.issue-body {
    padding: 10px 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 350px;
}

.issue-body::-webkit-scrollbar {
    width: 6px;
}

.issue-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.issue-body::-webkit-scrollbar-thumb {
    background: rgba(0, 115, 215, 1);
}

.issue-body::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 115, 215, 1);
}

.issue-create {
    padding: 8px 5px;
    padding-left: 10px;
    color: rgb(23, 43, 77);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    background-color: rgb(233, 242, 255);
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
}

.issue-create>i {
    margin-right: 5px;
}

.issue-item {
    padding: 10px 10px;
    color: rgb(23, 43, 77);
    font-family: Roboto, sans-serif;
    font-size: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(23, 43, 77, 0.2), 0 0 1px rgba(23, 43, 77, 0.2);
    box-sizing: border-box;
    margin-bottom: 5px;
}

.issue-item-title {
    font-size: 12px;
    font-weight: 600;
    padding: 5px 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.only-issue-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
    margin-right: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.issue-item-body {
    font-size: 11px;
    font-weight: 600;
}

.issue-item-con {
    padding: 2px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.issue-item-block {
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1px 4px;
    padding: 2px 5px;
    border-radius: 5px;
    flex-shrink: 0;
    height: 20px;
}

.issue-item-mode {
    color: rgb(94, 77, 178);
    background-color: rgb(223, 216, 253);
}

.issue-item-version {
    color: rgb(0, 85, 204);
    background-color: rgb(233, 242, 255);
}

.issue-item-db {
    color: rgb(33, 110, 78);
    background-color: rgb(220, 255, 241);
}

.mark-num {
    border-radius: 50%;
    min-height: 14px;
    min-width: 16px;
    padding: 2px;
    position: absolute;
    font-size: 8px;
    text-align: center;
    color: #ffffff;
    right: 0px;
    top: 5px;
}

.mark-num.priocolor_1 {
    background-color: #f66;
}

.mark-num.priocolor_2 {
    background-color: #fbbc04;
}

.mark-num.priocolor_3 {
    background-color: #34a853;
}

.offgray-background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(22, 41, 74, 0.30);
}

.no-display-popup {
    display: none;
}

.model-dialog-center {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
    justify-content: center !important;
}

.model-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 240px);
    pointer-events: auto;
    outline: 0;
    background-color: #ffffff;
    height: 595px;
    border-radius: 3px;
}

.model-dialog-header {
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e6e6e6;
}

.full-header-container {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.model-dialog-text {
    font-size: 20px;
    display: flex;
    width: max-content;
    font-family: Roboto, sans-serif;
    font-weight: 600;
}

.model-dialog-text>span {
    color: #00ace6;
}

.model-dialog-actions {
    display: flex;
    width: max-content;
    justify-content: flex-end;
    font-size: 25px;
}

.model-dialog-actions>i {
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
}

.model-dialog-actions .fa-share {
    font-size: 18px;
    margin-top: 4px;
}

.model-dialog-body {
    width: 100%;
    min-height: calc(100% - 115px);
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
}

.model-data-content {
    padding: 10px 32px;
    border-right: 2px solid #e6e6e6;
    max-height: 100%;
    overflow-y: scroll;
    flex: 0 0 auto;
    width: 75%;
}

.model-dialog-body>div {
    min-height: 100%;
    position: relative;
    max-height: 100%;
}

.model-other-content {
    flex: 0 0 auto;
    width: 25%;
}

.model-dialog-footer {
    padding: 10px;
    min-height: 48px;
    border-top: 2px solid #e6e6e6;
    text-align: end;
    max-height: 55px;
}

.input-label {
    color: #4d4d4d;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    width: 100%;
    font-family: Roboto, sans-serif;
    padding-top: 8px;
}

.input-formgroup {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    padding: 2px 20px;
    position: relative;
    width: 100%;
    font-family: Roboto, sans-serif;
}

.input-cell {
    width: 100%;
    padding: 5px 8px;
    position: relative;
    outline: none;
    border: 2.5px solid rgba(26, 163, 255, 1);
    background-color: #ffffff;
    border-radius: 2px;
    font-size: 13.5px;
}

.group-title {
    font-size: 16px;
}

.input-cell:focus {
    outline: none;
    border: 2.5px solid rgba(26, 163, 255, 1);
}

.my-button {
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 900;
    height: auto;
    letter-spacing: .8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px 15px;
    text-align: center;
    width: auto;
}

.big-cont::-webkit-scrollbar {
    width: 6px;
}

.big-cont::-webkit-scrollbar-track {
    background-color: transparent;
}

.big-cont::-webkit-scrollbar-thumb {
    background: #00ace6;
}

.big-cont::-webkit-scrollbar-thumb:hover {
    background: #00ace6;
}

/* Color */

.bg_lightgray {
    background-color: #f2f2f2;
}

.bg_gray {
    background-color: rgba(242, 242, 242, 0.6);
}

.bg-lightgreen {
    background-color: rgba(0, 153, 115, 0.9);
}

.bg-black {
    background-color: #4f585d;
}

.bg-spblue {
    background-color: #2196f3 !important;
}

.bg-green {
    background-color: #66bb6a !important;
}

.bg-lightblue {
    background-color: #64b5f6 !important;
}

.bg-lightred {
    background-color: #e57373 !important;
}

.bg-deeporange {
    background-color: #ff8a65 !important;
}

.bg-skyblue {
    background-color: #4dd0e1 !important;
}

.bg-yellow {
    background-color: #ffaa00 !important;
}

/*Loading Gif*/
.loading-background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.loading-img {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65px;
    pointer-events: auto;
    outline: 0;
}

.loading-cont {
    max-width: 200px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
    justify-content: center !important;
}

.right-part-action {
    width: max-content;
    float: right;
    font-size: 30px;
    margin-top: -5px;
}

.right-part-action>i {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.right-part-action>.fa-magnifying-glass-chart {
    color: #00ace6;
}

.float-action-button {
    position: fixed;
    bottom: 20px;
    z-index: 100;
    right: 25px;
    font-size: 25px;
    padding: 6.5px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.float-action-button.add {
    background-color: rgba(0, 153, 115, .9);
}

.float-filter-button {
    font-size: 25px;
    padding: 6.5px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    background-color: #2196f3;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.full-filter {
    position: fixed;
    bottom: 20px;
    z-index: 100;
    bottom: 90px;
    right: 25px;
}

.filter-menucont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
}

.filter-container {
    position: absolute;
    right: 60px;
    bottom: 10px;
    display: none;
    width: max-content;
}

.filter-menu {
    padding: 3px 12px;
    font-size: 13px;
    background-color: #00ace6;
    margin-bottom: 7px;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    text-align: right;
    width: max-content;
}

.filter-menu.selected {
    background-color: #00b386;
}

.filter-container.display {
    display: block;
}

.data-search {
    position: absolute;
    z-index: 50;
    right: 50px;
    display: none;
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    font-size: 15px;
    font-family: "Poppins";
}

.data-search.display {
    display: block;
}

.data-search input {
    padding: 5px;
    background-color: transparent;
    padding-left: 8px;
    color: #ffffff;
    font-weight: 500;
    width: 300px;
    border: none;
    outline: none;
}

.data-search::after {
    content: "";
    position: absolute;
    top: -10px;
    right: 5px;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.95) transparent;
}

.data-search input:focus {
    border: none;
    outline: none;
}

.from-popup {
    display: flex;
    width: 100%;
    position: relative;
    min-height: 100%;
    flex-direction: column;
}

.spcard.card-container {
    width: 100%;
    margin: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border-radius: 0px;
    min-height: 100%;
}

.spcard.card-container .card-title {
    max-width: 100%;
}

.sub-title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding: 5px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
}

.popupcontainter {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 180px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    color: #404040;
    -webkit-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    font-family: 'Lato', sans-serif;
    display: none;
}

.popupbody {
    font-size: 15px;
    padding: 10px;
    font-weight: 700;
}

.popupfooter {
    font-size: 13.5px;
    display: flex;
}

.popupbut {
    width: 50%;
    padding: 5px 8px;
    cursor: pointer;
    background-color: rgba(26, 163, 255, 1);
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1px;
    font-weight: 700;
}

.popupbut:first-child {
    border-right: 1px solid #ffffff;
}

.popupbut:last-child {
    border-left: 1px solid #ffffff;
}

.popupshow {
    display: block;
}

.popupbody>span {
    font-size: 13.5px;
    color: rgba(0, 179, 134, 1);
    font-weight: 700;
    text-transform: capitalize;
}

.version-data {
    font-size: 12px;
    color: #8c8c8c;
    text-align: center;
}

.version-data>span {
    color: #00ace6;
}

.softwear-name {
    font-family: "Agbalumo";
    color: #00ace6 !important;
    letter-spacing: 1px;
}

.softwear-name>span {
    color: #8c8c8c;
}

.timeline-toolbar {
    justify-content: end;
}


@media (max-width: 768px) {
    .topNav {
        vertical-align: middle;
        min-height: 65px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .mob-nav-toggle {
        display: block;
    }

    .logo-title {
        padding: 0px 2px;
        margin: auto;
        font-size: 26px;
        margin-top: 3px;
    }

    .optionItem {
        padding: 2px;
    }

    .profileimg {
        width: 40px;
        height: 40px;
    }

    .left-total-body {
        position: fixed;
        width: 70%;
        z-index: 100;
        left: -100%;
        transition: .8s;
    }

    .right-total-body {
        width: 100%;
        max-width: 100%;
    }

    .fullmenu {
        min-height: calc(100vh - 60px);
    }

    .left-total-body.mob-nav-display {
        left: 0;
        transition: 1s;
    }

    .greetType {
        font-size: 11px;
        margin-top: 2px;
    }

    .right-part {
        margin-top: 3px;
    }

    .project-title {
        padding: 10px 12px;
    }

    .model-dialog {
        width: 95%;
    }

    .model-dialog-header {
        padding-top: 10px;
        padding-left: 9px;
        padding-right: 9px;
        padding-bottom: 5px;
    }

    .model-dialog-body {
        overflow-y: scroll;
    }

    .model-dialog-text {
        font-size: 16px;
    }

    .full-issue-container {
        width: 100%;
        padding: 5px;
        max-height: calc(100vh - 110px);
    }

    .issue-container {
        width: 90%;
    }

    .model-other-content {
        display: none !important;
    }

    .model-data-content {
        width: 100%;
        padding: 10px 8px;
    }

    .model-dialog-body {
        min-height: calc(100% - 90px);
        max-height: calc(100% - 90px);
    }
}