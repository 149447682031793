body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.myRow {
  display: flex;
  flex-wrap: wrap;
}

.myRow>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

a {
  text-decoration: none !important;
  color: inherit;
}